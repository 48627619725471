import {getJobStagesApi} from '@/api/jobStagesApi';

import {FETCH_JOB_STAGES_REQUEST, FETCH_JOB_STAGES_SUCCESS, LOADING_STATE_SET} from './storeActions';

const getInitialState = () => {
  return {
    jobStages: [],
    jobStagesLoaded: false,
  };
};

const state = getInitialState();

const getters = {
  jobStages: (state) => state.jobStages,
  jobStagesLoaded: (state) => state.jobStagesLoaded,
};

const actions = {
  [FETCH_JOB_STAGES_REQUEST]: async ({commit}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Loading job stages'});
    try {
      const jobStages = await getJobStagesApi();
      if (!!jobStages) {
        commit(FETCH_JOB_STAGES_SUCCESS, {jobStages});
      }
    } catch (e) { } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
};

const mutations = {
  [FETCH_JOB_STAGES_SUCCESS]: (state, {jobStages}) => {
    state = Object.assign(state, {jobStages, jobStagesLoaded: true});
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
